.multi-filter .ant-select-arrow,
.multi-filter .ant-select-clear {
	margin-right: 16px;
}

.ant-picker {
	border: none;
	background-color: transparent;
}

.custom-placeholder-active input::placeholder {
	color: black !important;
}

.custom-placeholder-inactive input::placeholder {
	color: rgb(114, 114, 114) !important;
}
