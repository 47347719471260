.fixed-bg {
	height: 100vh;
	position: fixed;
	width: 100vw;
	/* background-color: #013e97; */
	background-color: theme("colors.primary");
}

.grain-texture {
	position: fixed;
	height: 100vh;
	width: 100vw;
	opacity: 0.3;
	background: url("../images/grain-texture.png");
}

.bg-circle {
	position: fixed;
	width: 1024px;
	height: 1024px;
	right: -300px;
	top: -644px;
	background: url("../images/bg-circle.png");
	opacity: 0.075;
}

.dark-gradient {
	position: fixed;
	width: 762px;
	height: 792px;
	right: -650px;
	top: -102px;
	background: #013a8c;
	opacity: 0.9;
	box-shadow: 0px 4px 4px 130px #000000;
	filter: blur(332px);
}

.globe-vector {
	position: fixed;
	background-repeat: no-repeat;
	height: 1200px;
	width: 700px;
	left: 0px;
	bottom: -1040px;
	background: url("../images/globe-vector.png");
	opacity: 0.15;
}

.yellow-gradient {
	position: fixed;
	width: 1500px;
	height: 1500px;
	left: -864px;
	top: 650px;
	background: #ce9d2c;
	opacity: 0.9;
	box-shadow: 0px 4px 4px 130px #000000;
	filter: blur(332px);
}
